import React, { Component } from "react";
import axios from "axios";
import { Link } from "gatsby";
import { withStyles } from "@material-ui/styles";
import Slider from "react-slick";
import Img from "gatsby-image";
import scrollTo from "gatsby-plugin-smoothscroll";

import Breadcrumb from "../../Breadcrumb";
import { CoreHeadingBlock } from "../../blocks/CoreHeadingBlock";
import { CoreButtonBlock } from "../../blocks/CoreButtonBlock";

import styles from "./styles";

const queryObject = (next = "") => {
  return {
    query: `
    {
      products(first: 50, after: "${next}") {
        edges {
          node {
            id
            link
            slug
            title
            modified
            product {
              code
              shortDescription
              hasHowtoGuide
              productGallery {
                sourceUrl(size: MEDIUM)
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  `,
  };
};

class HowTosListingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      products: this.props.prefill,
      searching: false,
      query: "",
    };

    this.settings = {
      dots: true,
      slidesToShow: 1,
      speed: 0,
      variableWidth: true,
      rows: 6,
      swipe: false,
      adaptiveHeight: true,
      afterChange: () =>
        setTimeout(() => scrollTo(`.${this.props.classes.results}`), 100),
    };
  }

  handleSubmit(e) {
    if (e) e.preventDefault();

    this.setState({
      products: [],
      searching: true,
      query: this.state.search,
    });

    this.fetchProducts();
  }

  fetchProducts(next = "") {
    axios
      .post(`${process.env.GATSBY_ADMIN_URL}graphql`, queryObject(next), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        return {
          products: res.data.data.products.edges.map((node) => node.node),
          next: res.data.data.products.pageInfo.endCursor,
          hasNextPage: res.data.data.products.pageInfo.hasNextPage,
        };
      })
      .then((data) => {
        return {
          products: data.products.filter(
            (product) => product.product.hasHowtoGuide
          ),
          next: data.next,
          hasNextPage: data.hasNextPage,
        };
      })
      .then((data) => {
        if (!this.state.search) return data;

        return {
          products: data.products.filter((product) => {
            if (
              product.title
                .toLowerCase()
                .includes(this.state.search.toLowerCase())
            )
              return true;
            if (product.product.code === Number(this.state.search)) return true;
            return false;
          }),
          next: data.next,
          hasNextPage: data.hasNextPage,
        };
      })
      .then((data) => {
        this.setState(
          {
            products: this.state.products.concat(data.products),
          },
          () => {
            if (data.hasNextPage) {
              this.fetchProducts(data.next);
            } else {
              this.setState({
                searching: false,
              });
            }
          }
        );
      });
  }

  setSearch(search) {
    this.setState({
      search,
    });
  }

  setStockists(stockists) {
    this.setState({
      stockists,
    });
  }

  setCoordinates(coordinates) {
    this.setState({
      searchCoordinaties: coordinates,
    });
  }

  setTitle(title) {
    this.setState({
      title,
    });
  }

  renderImage(product) {
    if (
      product.product.productGallery &&
      product.product.productGallery[0] &&
      product.product.productGallery[0].sourceUrl
    ) {
      return (
        <img
          src={product.product.productGallery[0].sourceUrl}
          alt={product.title}
        />
      );
    }
    if (
      product.product.productGallery &&
      product.product.productGallery[0] &&
      product.product.productGallery[0].localFile.childImageSharp.fluid
    ) {
      return (
        <Img
          fluid={
            product.product.productGallery[0].localFile.childImageSharp.fluid
          }
          alt={product.title}
        />
      );
    }

    return null;
  }

  renderError() {
    return (
      <>
        <p>Your search hasn’t matched any of our available How To pages.</p>
        <p>
          If you need expert advice regarding a TYDE product, simply{" "}
          <Link to="/about-us/contact-us">contact us</Link>.
        </p>
        <p>
          Alternatively, browse our{" "}
          <Link to="/expertise/faqs">FAQ section</Link>.
        </p>
      </>
    );
  }

  render() {
    const { classes } = this.props;
    const { products, searching, query } = this.state;

    return (
      <>
        <Breadcrumb
          type="how-to-listing"
          current={{
            title: "How To's",
            slug: "how-to",
            uri: "how-to",
          }}
        />
        <div className={"page-title " + classes.howsToTitle}>
          <div className="heading">
            <CoreHeadingBlock
              attributes={{
                align: "",
                anchor: "",
                className: "",
                content: "How to's",
                level: 1,
                textColor: "",
                __typename: "WpCoreHeadingBlockAttributes",
              }}
              innerBlocks={[]}
            />
          </div>
          <div className="search">
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <label htmlFor="search">Search for your product</label>
              <input
                type="text"
                name="search"
                minlength="2"
                required
                placeholder="Product Number, Product Name"
                value={this.state.search}
                onChange={(e) => this.setSearch(e.target.value)}
              />
              <input type="submit" value="search" />
            </form>
          </div>
        </div>
        {searching && <div className={classes.searching}>Searching...</div>}

        {products.length > 0 && (
          <section>
            <div className={classes.results}>
              <Slider {...this.settings}>
                {products.length &&
                  products.map((product, index) => {
                    return (
                      <div key={index} className="result-item">
                        {this.renderImage(product)}
                        <div className="result-content">
                          <p className="update">
                            Last updated {product.modified}
                          </p>
                          <h4>{product.title}</h4>
                          <p>{product.product.shortDescription}</p>
                          <CoreButtonBlock
                            attributes={{
                              align: "",
                              backgroundColor: "tertiary",
                              borderRadius: 0,
                              className: "view-all",
                              gradient: "",
                              linkTarget: "",
                              placeholder: "",
                              rel: "",
                              text: "Read More",
                              textColor: "primary",
                              title: "",
                              url: "/expertise/how-to/" + product.slug,
                              __typename: "WpCoreButtonBlockAttributes",
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </section>
        )}

        {query && !searching && !products.length && this.renderError()}
      </>
    );
  }
}

export default withStyles(styles)(HowTosListingPage);
