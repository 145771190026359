import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import HowTosListingPage from "../../components/HowTo/Pages/HowTosListingPage";

class Page extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout
        meta={{
          metaDescription:
            "Practical know-how with Cronex’s step-by-step guides. From DIY projects to expert tips, our ‘How To’ resources empower you to tackle any task.",
          metaTitle: "How to | Cronex",
          ogDescription: "",
          ogTitle: "",
          twitterCardType: "",
          twitterDescription: "",
          twitterTitle: "",
          canonical: "/expertise/how-to/",
        }}
        path={"a"}
        cta={"a"}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <HowTosListingPage
          prefill={this.props.data.allWpProduct.edges.map((edge) => edge.node)}
        />
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allWpProduct(
      sort: { fields: modifiedGmt }
      filter: { product: { hasHowtoGuide: { eq: true } } }
      limit: 1000
    ) {
      edges {
        node {
          id
          link
          slug
          title
          modified
          product {
            code
            shortDescription
            hasHowtoGuide
            productGallery {
              id
              localFile {
                id
                publicURL
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`;

export default Page;
